<template>
  <el-card style="min-height: 45rem">
    <div style="margin-bottom: 1rem">
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="searchForm.pay_phone" placeholder="购买人手机"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="searchForm.invite_phone" placeholder="推荐人手机"></el-input>
        </el-col>
        <el-col :span="8">
          <el-date-picker
              style="width: 100%"
              v-model="searchForm.time"
              type="daterange"
              range-separator="至"
              start-placeholder="支付时间范围开始日期"
              end-placeholder="支付时间范围结束日期">
          </el-date-picker>
        </el-col>
        <el-col :span="3">
          <el-select v-model="searchForm.status">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="待付款" :value="1"></el-option>
            <el-option label="已支付" :value="2"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button-group>
            <el-button @click="searchGo">搜索</el-button>
            <el-button @click="searchReset">重置</el-button>
          </el-button-group>
        </el-col>
      </el-row>
    </div>
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="fee" label="金额" show-overflow-tooltip></el-table-column>
      <el-table-column prop="rule_log.card" label="激活卡数量" show-overflow-tooltip></el-table-column>
      <el-table-column prop="uid" label="下单用户编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="u_info_id" label="下单用户主理人编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="u_phone" label="手机号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="u_nickname" label="昵称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="from_uid" label="推荐人编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="from_u_info_id" label="推荐人主理人编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="from_u_phone" label="手机号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="from_u_nickname" label="昵称" show-overflow-tooltip></el-table-column>

      <el-table-column prop="status" label="订单状态" :formatter="status2Tag" show-overflow-tooltip></el-table-column>
      <el-table-column prop="order_no" label="订单编号" show-overflow-tooltip></el-table-column>
      <el-table-column label="支付时间" show-overflow-tooltip>
        <template v-slot="s">{{s.row.pay_time | date}}</template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </el-card>
</template>

<script>

import {status2Tag} from "@/common/mod/plugin/privilegeCard/order";

export default {
  name: "order",
  data(){
    return{
      list:[],
      page:1,
      total:0,
      searchForm:{
        pay_phone: "",
        invite_phone: "",
        status:0,
        time:[],
      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    searchReset() {
      this.searchForm = {
        pay_phone: "",
        invite_phone: "",
        status:0,
        time:[],
      }
      this.searchGo();
    },
    searchGo() {
      this.page = 1;
      this.list = [];
      this.total = 0;
      this.load();
    },
    status2Tag(e){
      return status2Tag(e.status);
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$u.api.card.plugin.principal.orderSearch({
        page:this.page,
        ...this.searchForm,
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>